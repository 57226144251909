import { useContext } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { DELETE_FIELD, INQUIRY_STORE_ACCOUNT_ID } from "../../Helpers/ConstantProperties";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../Contexts/SnackbarProvider";
import { calculateGoodTotalsForAssignment, addDaysToToday } from "../../Helpers/helpers";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { getMutateObject, removeFromStore } from "../Dashboard/InquiryCalculations";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import GenericSpinner from "../GenericComponents/FormComponent/GenericSpinner";

function AssignGoods({ balanceDetails, inquiryID, canAllotBalance }) {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);
    const { showSnackbar } = useSnackbar();

    const queryFunction = async (obj) => {
        await Promise.all(
            Object.entries(obj).map(([productId, values]) => {
                return Promise.all(
                    Object.entries(values.value).map(async ([batchID, units]) => {

                        await serviceHelpers.patchBalance(token, khID, {
                            accountID: INQUIRY_STORE_ACCOUNT_ID,
                            resourceID: productId,
                            units: units,
                            fromBatch: batchID,
                            toBatch: inquiryID,
                            notes: `${batchID} has been transferred`,
                        });

                        const update = {
                            followUpDate: addDaysToToday(2),
                            readyToDispatch: true,
                        };

                        let comment = "Balance of " + units + " Allocated for " + values.name + " from batch: " + batchID + ".";

                        if (removeFromStore(balanceDetails, units)) {
                            update.storeNeeded = DELETE_FIELD;
                            comment += "No more Goods needed, inquiry moved out of store."
                        }

                        return await serviceHelpers.updateLeadStatus(
                            token, khID, update, inquiryID, comment);
                    })
                );
            })
        );
    };

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation(
        getMutateObject(queryClient, queryFunction, showSnackbar, "Assigned Succesfully", inquiryID)
    );

    const handleSubmit = () => {
        let obj = {}

        balanceDetails.filter(
            (product) => product.canAllot && product.remaining > 0)
            .forEach((details) => {
                obj[details.id] = {
                    value: calculateGoodTotalsForAssignment(details.storeBatches, details.remaining),
                    name: details.name
                }
            })
        mutate(obj)
    }

    if (isPending) <GenericSpinner />

    return (
        <Grid2 xs={12} md>
            <Button disabled={!canAllotBalance} variant="outlined" onClick={() => handleSubmit()}>
                Assign All FG
            </Button>
        </Grid2>
    )
}

export default AssignGoods;