import { Button, Typography } from "@mui/material";
import {
    addDaysToToday,
    CATEGORIES,
    checkValue,
    DecodeServerError,
    getCommentForCustomer,
} from "../../Helpers/helpers";
import { CONVERTED, FIVE_DAYS, QUOTED, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { useContext, useState } from "react";
import { AuthContext } from "../Auth/Auth";
import { FirmContext } from "../Contexts/FirmContext";
import { DateContext } from "../Contexts/DateContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserActionContext } from "./SummaryPage";
import { useSnackbar } from "../Contexts/SnackbarProvider";
import CreatePoDialogForm from "../Order/CreatePoDialogForm";
import GenericDialog from "../GenericComponents/Dialog/GenericDialog";

function DashboardUpdateStatusAction({ item }) {
    const { token } = useContext(AuthContext);
    const { currentFirm } = useContext(FirmContext);
    const { currentDate } = useContext(DateContext);
    const { showAssigned } = useContext(UserActionContext);
    const { showSnackbar } = useSnackbar();

    const [openDialog, setOpenDialog] = useState(false);
    const queryClient = useQueryClient();

    const statusCategory = CATEGORIES[item.status]

    const onUpdate = async (state) => {
        setOpenDialog(false);
        const update = {
            followUpDate: addDaysToToday(2, currentDate),
            status: statusCategory.nextStatus,
        };

        if (item.status === QUOTED) {
            update.poNumber = state.poNumber;
        }

        if (update.status === CONVERTED && item.customerId) {
            await serviceHelpers.updateCustomer(
                token,
                currentFirm.khID,
                { followUpDate: addDaysToToday(FIVE_DAYS, currentDate) },
                item.customerId,
                getCommentForCustomer(item.customerId, FIVE_DAYS),
                currentDate
            );
        }

        const comment = `Status updated to ${CATEGORIES[statusCategory.nextStatus].name}`;

        const data = await serviceHelpers.updateLeadStatus(
            token,
            currentFirm.khID,
            update,
            item.id,
            comment,
            currentDate,
            item
        );
        return { id: data.id, comment };
    }

    const queryFunction = async () => {
        if (item.status === QUOTED) {
            setOpenDialog(true)
        } else {
            await onUpdate()
        }
    }

    const { mutate } = useMutation({
        mutationFn: queryFunction,
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey.includes(UPDATE_ON_INQUIRY),
            });
            if (data) showSnackbar(`${data?.comment}`, 'success');
        },

        onError: (error) => {
            const message = DecodeServerError(error);
            showSnackbar(message, 'error');
        },
    });

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    if (showAssigned) {
        const owner = item.assignee ? item.assignee.displayName : "Not Assigned yet";
        return (
            <Typography sx={{ textAlign: { xs: 'left', sm: 'center' }, whiteSpace: 'nowrap' }}>
                {owner}
            </Typography>
        )
    }

    const disabled = CATEGORIES[item.status]?.disableBtn
        ? CATEGORIES[item.status]?.disableBtn(item)
        : false;

    const dialogContent = (
        <CreatePoDialogForm
            data={item}
            callback={onUpdate}
            onClose={handleDialogClose}
        />
    );

    return (
        <>
            <Button
                variant="outlined"
                disabled={disabled}
                sx={{ width: '100%', whiteSpace: 'nowrap' }}
                onClick={() => mutate()}>
                {checkValue(statusCategory?.btnText)}
            </Button>

            <GenericDialog
                open={openDialog}
                setOpen={setOpenDialog}
                title="Enter PO Number"
                content={dialogContent}
            />
        </>
    )
}

export default DashboardUpdateStatusAction;
