import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { commonFontSize, commonFontWeight } from "../../Helpers/ConstantProperties";
import { checkValueNumber } from "../../Helpers/helpers";
import React from "react";

export default function InquiryStockDetails({ balanceDetails }) {
    return (
        <Grid2 container>
            <Grid2 xs={12} className="d-none d-sm-block">
                <Typography
                    component={"span"}
                    fontSize={commonFontSize}
                    fontWeight={commonFontWeight}>
                    <Grid2
                        textAlign={"center"}
                        container
                        className='bg-primary-subtle rounded p-2'>
                        <Grid2 md={3}>Product Name</Grid2>
                        <Grid2 md>Required</Grid2>
                        <Grid2 md>Released</Grid2>
                        <Grid2 md>Passed</Grid2>
                        <Grid2 md>Rejected</Grid2>
                        <Grid2 md>Remaining</Grid2>
                        <Grid2 md>Stock</Grid2>
                    </Grid2>
                </Typography>
            </Grid2>

            <Grid2 xs={12}>
                <Typography component={"span"} fontSize={commonFontSize}>
                    {
                        balanceDetails.map((product) => {
                            const list = [product];

                            return (
                                <React.Fragment key={product.id}>
                                    {
                                        list.map((details, index) => (
                                            <Grid2
                                                key={index}
                                                textAlign={"center"}
                                                container
                                                className="p-2">

                                                <Grid2 xs={12} md={3}>{details.name}</Grid2>
                                                <Grid2 xs={12} md>
                                                    {checkValueNumber(details.required)}
                                                </Grid2>
                                                <Grid2 xs={12} md>
                                                    {checkValueNumber(details.released)}
                                                </Grid2>
                                                <Grid2 xs={12} md>
                                                    {checkValueNumber(details.passed)}
                                                </Grid2>
                                                <Grid2 xs={12} md>
                                                    {checkValueNumber(details.rejected)}
                                                </Grid2>
                                                <Grid2 xs={12} md>
                                                    {checkValueNumber(details.remaining)}
                                                </Grid2>
                                                <Grid2 xs={12} md>
                                                    {checkValueNumber(details.storeBalance)}
                                                </Grid2>
                                            </Grid2>
                                        ))
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </Typography>
            </Grid2>
        </Grid2>
    )

}