import Grid2 from "@mui/material/Unstable_Grid2"
import { InquiryGridItem } from "../Inquiry/InquiryGridItem"
import { checkValue, getLocalDateString, ShowNumber } from "../../Helpers/helpers"

export const CustomerInformation = ({ item }) => {
    var screenShow = { show: "d-sm-flex d-none flex-grow-1" }
    return (
        <Grid2 className="border border-1 m-1 p-2">
            <Grid2 className="px-2 d-flex flex-column gap-3 col-12">
                <Grid2 container gap={1} direction={"row"} width={'100%'}>
                    <InquiryGridItem display={"flex-grow-1"} xl={2} xs={4} title={'Contact Person'} value={checkValue(item.contactPerson)} />
                    <InquiryGridItem display={screenShow.show} xl={2} xs={6} title={'E-mail ID'} value={item.email} />
                    <InquiryGridItem display={screenShow.show} xl={3} xs={6} title={'Contact No'} value={item.phoneNumber} />
                    <InquiryGridItem display={"flex-grow-1"} xl={2} xs={4} title={'City'} value={checkValue(item.city)} />
                </Grid2>
                <Grid2 container gap={1} direction={"row"} width={'100%'}>
                    <InquiryGridItem display={"flex-grow-1"} xl={2} xs={4} title={'Last Update'} value={getLocalDateString(item.lastUpdated)} />
                    <InquiryGridItem display={"flex-grow-1"} xl={3} xs={4} title={'Next Follow Up'} value={getLocalDateString(item.followUpDate)} />
                </Grid2>
                <Grid2 container gap={1} direction={"row"} width={'100%'}>
                    <InquiryGridItem display={"flex-grow-1"} xl={2} xs={6} title={'Discount'} value={checkValue(`${item.discountRate}%` || ShowNumber(item.discountRate, 2, true))} />
                    <InquiryGridItem display={"flex-grow-1"} xl={2} xs={6} title={'Shipping Address'} value={checkValue(`${item.shippingAddress}`)} />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}