import { useContext } from "react";
import { checkValue, getLocalDateString } from "../../Helpers/helpers";
import DocumentFormat from "../GenericComponents/Layout/DocumentFormat"
import { getRateDetails } from "../Quotation/RateCalculator";
import { FirmContext } from "../Contexts/FirmContext";
import { DOC_INVOICE } from "../../Helpers/ConstantProperties";
import { DocumentFormatContext } from "../Contexts/DocumentFormatContext";

const InvoiceFormat = ({ item }) => {
    const { currentFirm } = useContext(FirmContext);
    const { selectedFormat } = useContext(DocumentFormatContext);

    const documentTitle = item.invoiceId ? "Tax Invoice" : "Proforma Invoice";
    const documentID = item.invoiceId ? item.invoiceId : documentTitle;
    const documentDate = item.invoiceDate ? new Date(item.invoiceDate * 1) : new Date();

    const cData = getRateDetails({
        inquiry: item,
        currentFirm: currentFirm,
        docFormat: selectedFormat
    });

    const context = {
        document: DOC_INVOICE,
        documentTitle: documentTitle,
        inquiry: item,
        cData: cData,
        detailsToShow: {
            ID: documentID,
            Date: getLocalDateString(documentDate),
            PO: checkValue(item.poNumber)
        }
    }

    return <DocumentFormat context={context} />
}

export default InvoiceFormat