import { useContext } from "react";
import { INTERNAL_ORDER, NEW_ORDER, UPDATE_ON_INQUIRY } from "../../Helpers/ConstantProperties";
import { InquiryForm, propertyGroups } from "../../Helpers/ExtraProperties";
import { getInquiryObject, getUpdatedProp, InquiryPostDispatch, inwordIdWithDate } from "../../Helpers/helpers";
import { serviceHelpers } from "../../Helpers/ServiceHelpers";
import { GenericErrorComponent, GenericSuccessComponent } from "../GenericComponents/FormComponent/GenericAlertComponent";
import GenericForm from "../GenericComponents/FormComponent/GenericForm";
import { GenericVerifyComponent } from "../GenericComponents/VerifyComponent/FirmVerifyComponent";
import InquiryProductDetails from "../Inquiry/InquiryProductDetails";
import { DateContext } from "../Contexts/DateContext";

export const CreateInternalOrder = () => {
    const { currentData } = useContext(DateContext)
    const handleReditect = () => { }

    const queryFunction = async (state, token, khID) => {
        state = getInquiryObject(state);
        delete state.isGroupEdited;

        state.id = inwordIdWithDate()
        state.internalOrder = true;
        state.customerName = INTERNAL_ORDER;

        return await serviceHelpers.createOrder(token, khID, state, currentData)
    }

    const successMessage = "Internal Order Is Created"

    const FormProperties = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText: "Create Internal Order",
        navigateTo: NEW_ORDER,
        queryKeyValue: UPDATE_ON_INQUIRY,
        enableVerify: true,
        propertyList: [{
            ...propertyGroups.product_grp,
            group: [
                getUpdatedProp(InquiryForm.ProductDropDown, { enableNewAdd: false }),
                InquiryForm.quantity,
                // InquiryForm.Rate,
                InquiryForm.ProductDescription,
            ]
        }],
        VerifyAlertComponent: GenericVerifyComponent,
        queryFunction: queryFunction,
        buttonClasses: "",
        currentData: {},
        handleCancel: handleReditect,
        enableClear: true,
        afterDispatch: InquiryPostDispatch,
        formTitle: "Add Internal Order",
        GroupDetailsComponent: InquiryProductDetails,
    };

    return (
        <GenericForm
            {...FormProperties}
        />
    )
}