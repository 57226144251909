import { checkInqHasOnlyProcessNoRM, checkInquiryStatusAndProductType } from "./helpers";

export const updateRequiredPropsForOrder = (updateObject, inquiry) => {
    // If there are any custom products then mark in Design
    // Else move it to store.
    const details = checkInquiryStatusAndProductType(inquiry);

    if (details.hasCustomProduct) {
        updateObject.designNeeded = true;
    }
    else {
        updateObject.storeNeeded = true;
    }

    // If Inquiry has only processes and not RM
    // then mark for Production
    if (checkInqHasOnlyProcessNoRM(inquiry)) updateObject.prodNeeded = true;
}