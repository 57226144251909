import {
    BALANCE,
    INQUIRY_STORE_ACCOUNT_ID,
    PROCESSESS,
    PRODUCT_TYPE_SEMIFINISHED,
    PRODUCTION_PLAN,
    PRODUCTION_PLAN_RESOURCE_ID,
    RMLIST,
    UPDATE_ON_INQUIRY,
    userLevels
} from '../../Helpers/ConstantProperties'
import { DecodeServerError, filterAndSortBatchObject, getFgRate, getRoundUptoTwoPlaces, getSaleRate, getTotalUnitsForReq } from '../../Helpers/helpers'
import { serviceHelpers } from '../../Helpers/ServiceHelpers'

export const getStoreProductQueryObject = (token, khID, productID) => ({
    queryKey: [
        token,
        khID,
        BALANCE + INQUIRY_STORE_ACCOUNT_ID,
        productID
    ],
    queryFn: async () => {
        const storeOptions = {
            accountID: INQUIRY_STORE_ACCOUNT_ID,
            resourceID: productID,
            date: Date.now(),
        }

        return await serviceHelpers.getResourceBalance(token, khID, storeOptions)
    }
})

export const getProdPlanQureyObject = (token, khID, inquiryID) => {
    return {
        queryKey: [
            token,
            khID,
            BALANCE + inquiryID,
            PRODUCTION_PLAN_RESOURCE_ID
        ],
        queryFn: async () => {
            const balanceOption = {
                accountID: inquiryID,
                resourceID: PRODUCTION_PLAN_RESOURCE_ID,
                date: Date.now(),
                withBatches: true,
            }

            return await serviceHelpers.getResourceBalance(token, khID, balanceOption)
        }
    }
}

export const getProductWisePlanning = (plannedBalance) => {
    const retObject = {}

    if (!plannedBalance) return retObject;

    Object.values(plannedBalance.batches).forEach((batch) => {
        if (batch.BatchObject?.productID) {
            retObject[batch.BatchObject.productID] = batch;
        }
    })

    return retObject;
}

const getStoreBalanceForProduct = (storeResult, productID) => {

    const storeBalanceResult = storeResult.find(
        (res) => res.data[0]?.resourceID === productID);

    return storeBalanceResult?.data[0];
}

const getDispatchCount = (inquiry, productID) => {
    if (!inquiry.dispatch) return 0;

    return (inquiry.dispatch[productID] || 0)
}

export const getProductResultDetails = (
    inquiry,
    planResultData,
    storeResult = null,
    disableTrade = false) => {

    const products = [];
    const planResult = getProductWisePlanning(planResultData);

    const productsToManu = [...(inquiry.products || []), ...(inquiry.sfg || [])];

    const quantities = getTotalQuantity(inquiry);

    productsToManu.forEach((product) => {
        // For all the products, first get all the common props
        const units = product.units ? product.units : quantities[product.product.id].units;
        const isSFG = product.product.type === PRODUCT_TYPE_SEMIFINISHED;

        const productData = {
            id: product.product.id,
            name: product.product.name,
            description: product.product.description,

            saleRate: getSaleRate(product),
            GSTRate: product.product.GSTRate,
            productHSNcode: product.product.productHSNcode,

            required: parseFloat(units),

            inStore: 0,
            storeBalance: 0,

            prodPossible: 0,
            planned: 0,

            inQC: 0,
            atVendor: 0,
            passed: 0,
            failed: 0,
            rejected: 0,

            dispatched: getDispatchCount(inquiry, product.product.id),
            showInDispatch: !isSFG,

            rmlist: [],
            processes: [],

            hasRM: product[RMLIST]?.length > 0,
            hasProcess: (product[PROCESSESS]?.length > 0) || disableTrade
        }

        // If the product have RM then store can release something
        // if that is not the case, then consider all that the
        // RM (Which is 0) is already released.
        productData.released = (!productData.hasRM && productData.hasProcess)
            ? productData.required
            : 0;

        if (productData.hasRM) {
            // This flag will help the QC process, if the product with
            // this flag got rejected, then we need to enable the store
            productData.enableStore = true;
        }
        else {
            // This flag will help the QC process, if the product with
            // this flag got rejected, then we need to enable the inquiry
            // for production and ignore the store.
            productData.enableProd = true;
        }

        if (storeResult) {
            const storeBalance = getStoreBalanceForProduct(storeResult, productData.id);

            if (storeBalance) {
                productData.storeBatches = filterAndSortBatchObject(storeBalance.batches);

                // This is the total balance store can allot to the inquiry. It is not the same
                // as the total store balance as there are other inquiry balance are present in
                // the store.
                productData.storeBalance =
                    Object.values(productData.storeBatches).reduce(
                        (total, units) => total + units, 0);

                // This is the balance of this inquiry in the store.
                productData.inStore = (storeBalance.batches[inquiry.id] || 0)
            }
        }

        // Now add details from the Production Plan
        const productionResult = planResult[product.product.id];

        if (productionResult) {
            const batchObject = productionResult.BatchObject;

            productData.released = productData.hasRM
                ? parseFloat(productionResult.units)
                : productData.released;


            productData.inQC = batchObject.inQC || 0
            productData.passed = batchObject.passed || 0
            productData.failed = batchObject.failed || 0
            productData.rejected = batchObject.rejected || 0
            productData.atVendor = batchObject.atVendor || 0
            productData.vendorID = batchObject.vendorID
            productData.batchID = batchObject.id
        }

        // Now make other calculations
        productData.inProduction = productData.released - productData.passed - productData.atVendor;

        productData.remaining = productData.required - productData.released;
        productData.remainingDispatch = productData.required - productData.dispatched;

        if (productData.hasRM) {

            // In case of no RM, the rejected quantity did not consume
            // any resources, so we remove the effect from the calculations.
            productData.inProduction -= productData.rejected;

            productData.remaining += productData.rejected;
        }

        // This number shows, how many units still needs to produce
        productData.remainingProduction = productData.inProduction - productData.inQC

        // System can only allot FG if the remaining items are going to 0
        // There is no reason to just allot half requirement and wait for
        // other half to come from other sources.
        productData.canAllot =
            productData.remaining !== 0 &&
            productData.remaining <= (productData.storeBalance);

        // Now lets tackle the requirements
        product[RMLIST]?.forEach((req) => {
            const reqObject = {
                id: req.product.id,
                name: req.product.name,
                required: getTotalUnitsForReq(req, productData.required),
                fgRate: getFgRate(req, productData.required),
                fgID: productData.id,
                inStore: 0,
                storeBalance: 0,
            }

            const isSFG = req.product.type === PRODUCT_TYPE_SEMIFINISHED;

            if (storeResult) {
                const storeBalance = getStoreBalanceForProduct(storeResult, reqObject.id);

                if (storeBalance) {

                    reqObject.storeBatches = isSFG
                        ? storeBalance.batches
                        : filterAndSortBatchObject(storeBalance.batches);


                    reqObject.storeBalance =
                        Object.values(reqObject.storeBatches).reduce(
                            (total, units) => total + units, 0);
                }
            }

            reqObject.released = productData.released * reqObject.fgRate;
            reqObject.remaining = productData.remaining * reqObject.fgRate;

            productData.rmlist.push(reqObject);
        })

        product[PROCESSESS]?.forEach((req) => {

            const reqObject = {
                id: req.product.id,
                name: req.product.name,
                required: getTotalUnitsForReq(req, productData.required),
                fgRate: getFgRate(req, productData.required),
                fgID: productData.id,
            }

            productData.processes.push(reqObject);
        })

        if (productData.hasRM) {

            // This needs to happen after we done with the RM calculations
            // so cant move up.
            productData.prodPossible = productData.planned + getFGWiseRequirement(productData);
        }

        products.push(productData);
    })

    return products;
}

export const getFGWiseRequirement = (product, plannedRM = {}) => {
    // This functions returns the MIN of remaining and Possible Production as
    // per the store balance.

    // When we add a planning for one of the product, we want the total
    // remaining of other products to go down but not the one we updated.
    // This is not easy to do in a single iteration. Hence we add the planned
    // production back to min Possible at the return value.
    const remaining = product.remaining - product.planned

    return product.rmlist.reduce((possible, raw) => {

        const storeBalance = raw.storeBalance - (plannedRM[raw.id]?.units || 0);

        const maxPossible = getRoundUptoTwoPlaces(storeBalance / raw.fgRate);
        raw.maxPossible = Math.min(maxPossible, remaining);

        return Math.min(possible, raw.maxPossible);

    }, remaining);
}

export const getPlannedRM = (balanceDetails, withBatch = false) => {
    const plannedRM = {}
    const plannedFG = {}

    balanceDetails.forEach((product) => {
        if (product.planned > 0) {
            plannedFG[product.id] = {
                batchID: product.batchID,
                planned: product.planned
            };
        }

        product.rmlist.forEach((raw) => {
            if (raw.planned) {
                plannedRM[raw.id] = plannedRM[raw.id] ? plannedRM[raw.id] : { units: 0 };
                plannedRM[raw.id].units += (raw.planned)

                if (withBatch) {
                    plannedRM[raw.id].batches = raw.storeBatches
                }
            }
        })
    })

    return { plannedRM, plannedFG };
}

export const updateProdPossible = (balanceDetails) => {
    // This whole method is not really optimized
    // We are iterating product array multiple times.
    // This is not optimal and we need to think a better design.

    // For now we start with this implimentation as the number of
    // products in an inquiry may not be that many.
    const { plannedRM } = getPlannedRM(balanceDetails);
    let disableButton = true;

    balanceDetails.forEach((product) => {

        // When we add a planning for one of the product, we want the total
        // remaining of other products to go down but not the one we updated.
        // This is not easy to do in a single iteration. Hence we add the planned
        // production back to min Possible so to keep the calculation correct.
        product.prodPossible = product.planned + getFGWiseRequirement(product, plannedRM);

        // Check if all the requirement is within limit
        // If any of the planned data is less than possible, we can save the data
        // so enable the save button.
        if (product.planned <= product.prodPossible) disableButton = false;
    })

    return disableButton;
}

export const getProductPlanBatchObject = (productID, inquiryID) => {
    return {
        productID: productID,
        inquiryID: inquiryID,
        name: PRODUCTION_PLAN,
        entityPublicAccess: true,
        effectAccess: userLevels.map((level) => level.id)
    }
}

export const getMutateObject = (
    queryClient,
    queryFunction,
    showSnackbar,
    message,
    inquiryID,
    navigateTo = null
) => {
    return {
        mutationFn: queryFunction,

        onSuccess: async (data) => {
            await queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey.includes(BALANCE + inquiryID) ||
                    query.queryKey.includes(BALANCE + INQUIRY_STORE_ACCOUNT_ID) ||
                    query.queryKey.includes(UPDATE_ON_INQUIRY)
            })

            showSnackbar(message, 'success')
            if (navigateTo) navigateTo(data)
        },
        onError: (error) => {
            const message = DecodeServerError(error);
            showSnackbar(message, 'error');
        },
    }
}

export const isProductionNeeded = (balance) => {
    const totalRemaining = balance.reduce(
        (total, product) => total + (product.remainingProduction || 0), 0);

    const totalPlanned = balance.reduce(
        (total, product) => total + product.planned, 0);

    return totalRemaining > totalPlanned;
}

export const removeFromStore = (balance, relesedUnits) => {
    const totalRemaining = balance.reduce((total, product) => total + product.remaining, 0);
    return totalRemaining <= relesedUnits;
}

export const getTotalQuantity = (inquiry) => {
    const quantities = {};

    // For all Products
    inquiry.products.forEach((product) => {

        product[RMLIST]?.forEach((req) => {
            const requiredQty = parseFloat(getTotalUnitsForReq(req, product.units));

            if (quantities[req.product.id]) {
                quantities[req.product.id].units += requiredQty;
            }
            else {
                quantities[req.product.id] = {
                    name: req.product.name,
                    saleRate: req.product.saleRate,
                    units: requiredQty
                }
            }
        })
    })

    // for all SFG in reverse order
    inquiry.sfg?.toReversed().forEach((sfg) => {
        const sfgQty = quantities[sfg.product.id]?.units || 1;

        sfg[RMLIST]?.forEach((req) => {
            const requiredQty = parseFloat(getTotalUnitsForReq(req, sfgQty));

            if (quantities[req.product.id]) {
                quantities[req.product.id].units += requiredQty;
            }
            else {
                quantities[req.product.id] = {
                    name: req.product.name,
                    saleRate: req.product.saleRate,
                    units: requiredQty
                }
            }
        })
    })

    return quantities;
}