import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { NEW_ORDER } from '../../Helpers/ConstantProperties';
import CreateInquiry from '../Inquiry/CreateInquiry';
import { CreateOrderProps } from '../../Helpers/ExtraProperties';
import { createServerErrorMsg } from '../../Helpers/helpers';

const CreateOrder = () => {
    const queryFunction = async (token, khID, state, currentDate) => {

        if (!state.poNumber && !state.contactPerson) {
            return Promise.reject(createServerErrorMsg("Please Add Customer Name Or PO Number"));
        }
        return await serviceHelpers.createOrder(token, khID, state, currentDate);
    }

    return (
        <CreateInquiry
            propertyList={CreateOrderProps}
            queryFunction={queryFunction}
            successMessage="Order created successfully"
            formTitle="Add New Order"
            navigateTo={NEW_ORDER}
        />
    )
}
export default CreateOrder
