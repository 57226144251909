import { GenericVerifyComponent } from '../GenericComponents/VerifyComponent/FirmVerifyComponent';
import GenericForm from '../GenericComponents/FormComponent/GenericForm';
import { ADVANCE_PAYMENT_TERM, HOME, SALES_MANAGER_USER_LEVEL_ID, TRANSPORT_TERM, UPDATE_ON_INQUIRY } from '../../Helpers/ConstantProperties';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { getPropsWithProductList, InquiryProps } from '../../Helpers/ExtraProperties';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { DateContext } from '../Contexts/DateContext';
import InquiryProductDetails from './InquiryProductDetails';
import { canEditSaleRate, checkAccessLevel, getInquiryObject, InquiryPostDispatch } from '../../Helpers/helpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';

const CreateInquiry = ({ propertyList = InquiryProps, queryFunction, successMessage, navigateTo = HOME, formTitle }) => {
    const { currentDate } = useContext(DateContext);
    const { currentFirm } = useContext(FirmContext);
    const { currentUserObject } = useContext(AuthContext);

    propertyList = getPropsWithProductList(propertyList, canEditSaleRate(currentFirm));

    const createInquiryFunction = async (state, token, khID) => {
        state = getInquiryObject(state);
        if (checkAccessLevel(currentFirm.currentAccess, [SALES_MANAGER_USER_LEVEL_ID])) {
            state.assignee = {
                id: currentUserObject.uid,
                displayName: currentUserObject.displayName
            }
        }

        state.paymentTerms = state.paymentTerms || ADVANCE_PAYMENT_TERM;
        state.transportTerms = state.transportTerms || TRANSPORT_TERM;

        state.products = await serviceHelpers.createCustomProducts(token, khID, state.products);

        const response = await queryFunction(token, khID, state, currentDate);

        return response;
    }

    const navigate = useNavigate();

    const handleReditect = () => {
        navigate(navigateTo)
    }

    const FormProperties = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText: "Add",
        navigateTo: navigateTo,
        queryKeyValue: UPDATE_ON_INQUIRY,
        enableVerify: true,
        formTitle: formTitle,
        propertyList: propertyList,
        VerifyAlertComponent: GenericVerifyComponent,
        queryFunction: createInquiryFunction,
        buttonClasses: "",
        currentData: {},
        handleCancel: handleReditect,
        afterDispatch: InquiryPostDispatch,
        GroupDetailsComponent: InquiryProductDetails,
        enableClear: true
    };

    return (
        <GenericForm
            {...FormProperties}
        />
    )
}
export default CreateInquiry
