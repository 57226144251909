import { Typography } from "@mui/material";
import { commonFontSize, commonFontWeight, PRIMARY_COLOR } from "../../Helpers/ConstantProperties";
import { ShowNumber } from "../../Helpers/helpers";

export default function InquiryTotalValue({ cData }) {
    return (
        <Typography
            fontWeight={commonFontWeight}
            fontSize={commonFontSize}
            color={PRIMARY_COLOR}
            paddingRight={{ xs: 0, md: 2 }}
            textAlign={{ xs: "center", md: "right" }}>
            {
                cData.roundedTotal ? ShowNumber(cData.roundedTotal, 2, true) : "Not Available"
            }
        </Typography>
    )
}